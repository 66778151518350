<template>
  <component :is="organizationData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="organizationData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching organization data
      </h4>
      <div class="alert-body">
        No organization found with this organization id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-organizations-list'}"
        >
          Organization List
        </b-link>
        for other organizations.
      </div>
    </b-alert>

 
      <organization-edit-tab-account
        v-if="organizationData"
        :organization-data="organizationData"
        :operation-types-options="allOperationTypes"
        class="mt-2 pt-75"
      />
  </component>
</template>

<script>
import {
   BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import organizationStoreModule from '../organizationStoreModule'
import OrganizationEditTabAccount from './OrganizationEditTabAccount.vue'

export default {
  components: {
    BCard,
    BAlert,
    BLink,
    OrganizationEditTabAccount
  },
  setup() {
    const organizationData = ref(null)

    const ORGANIZATION_APP_STORE_MODULE_NAME = 'app-organization'
    // Register module
    if (!store.hasModule(ORGANIZATION_APP_STORE_MODULE_NAME)) store.registerModule(ORGANIZATION_APP_STORE_MODULE_NAME, organizationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ORGANIZATION_APP_STORE_MODULE_NAME)) store.unregisterModule(ORGANIZATION_APP_STORE_MODULE_NAME);
    })

    store.dispatch('app-organization/fetchOrganization', { id: router.currentRoute.params.id })
      .then(response => { 
        organizationData.value = response.data;
      
      })
      .catch(error => {
        if (error.response.status === 404) {
          organizationData.value = undefined
        }
      })
    const allOperationTypes = [
      { label: 'All', value: 'ALL' },
      { label: 'Image search', value: 'IMAGE_SEARCH' },
      { label: 'Image editor', value: 'IMAGE_EDITOR' },
      { label: 'Contilt scheme', value: 'CONTILT_SCHEME' },
    ]
    return {
      organizationData,
      allOperationTypes
    }
  },
}
</script>

<style>

</style>
